import { rgba } from "polished";

const colors = {
  transparent: "transparent", // 0
  black: "#000000", // 1
  white: "#ffffff", // 2
  headingColor: "#132636", // 3
  textColor: rgba("#132636", 0.65), // 4
  textColorAlt: "#36526C", // 5
  textColorLight: rgba("#fff", 0.7), // 6
  labelColor: "#767676", // 7
  inactiveField: "#f2f2f2", // 8
  inactiveButton: "#b7dbdd", // 9
  inactiveIcon: "#EBEBEB", // 10
  primary: "#FF766E", // 11
  primaryHover: "#ff544a", // 12
  secondary: "#FF8030", // 13
  borderColor: "", //14
  linkColor: "#FF766E", // 15
  gradientPrimary: "#F4BA4F",
  gradientSecondary: "#FF8030",
  xrdYellow: "#F4BA4F",
  xrdOrange: "#FF8030",
  xrdTeal: "#09BAB5",
  xrdNavyBlue: "#132636",
  primaryBoxShadow: "0px 8px 20px -6px rgba(42, 162, 117, 0.57)",
  secondaryBoxShadow: "0px 8px 20px -6px rgba(237, 205, 55, 0.5)",
};

export default colors;
