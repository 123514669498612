import ResetCSS from "../common/assets/css/style";
import { theme } from "../common/theme/secD3v";
import SEO from "../components/SEO";
import Page404 from "../containers/SecD3v/404";

import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from "../containers/SecD3v/secd3v.style";

import React from "react";
import Sticky from "react-stickynode";
import { ThemeProvider } from "styled-components";

const SecD3v = () => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <SEO title="Home" />
        <ResetCSS />
        <GlobalStyle />
        {/* start app classic landing */}
        <AppWrapper>
          <ContentWrapper>
            <Page404 />
          </ContentWrapper>
        </AppWrapper>
      </>
    </ThemeProvider>
  );
};

export default SecD3v;
